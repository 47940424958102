import React, { useState, useEffect } from "react";
import {
  Tooltip,
  Backdrop,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Checkbox,
} from "@mui/material";
import { useAuth } from "react-oidc-context";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownloadInvoiceIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DownloadInvoicesIcon from "@mui/icons-material/DownloadOutlined";
import DownloadReportIcon from "@mui/icons-material/SummarizeOutlined";

import {
  InvoicesWhereQuery,
  useInvoiceFullQuery,
} from "../../../generated/graphql";
import { format } from "date-fns";
import InvoiceItemsTable from "./InvoiceItemsTable";
import PaginationButtons from "../../TablePagination";
import theme from "../../../Theme";
import {
  handleInvoicesDownload,
  handleInvoiceDownload,
  handleReportDownload,
} from "../../lib/Tools";

interface Props {
  invoiceData: InvoicesWhereQuery;
  handlePrevPage: () => void;
  handleNextPage: () => void;
  currentPage: number;
  totalPages: number;
  isCustomer: boolean;
}

// This is a wrapper to load a table with invoice items
const InvoiceItemsTableWrapper: React.FC<{ invoiceNumber: number }> = ({
  invoiceNumber,
}) => {
  const { data, loading, error, refetch } = useInvoiceFullQuery({
    variables: {
      where: { number: invoiceNumber },
    },
  });

  useEffect(() => {
    refetch();
  }, [invoiceNumber]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return data && data.invoice ? <InvoiceItemsTable invoice={data} /> : null;
};

const Invoices: React.FC<Props> = ({
  invoiceData,
  isCustomer,
  handlePrevPage,
  handleNextPage,
  currentPage,
  totalPages,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: null | HTMLElement;
  }>({});
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [openInvoiceId, setOpenInvoiceId] = useState<null | number>(null);
  const handleClose = (invoiceNumber: number) => {
    setAnchorEl((prevState) => ({ ...prevState, [invoiceNumber]: null }));
  };

  const [selectedRows, setSelectedRows] = useState<Record<number, boolean>>({});

  const toggleSelectAll = () => {
    const allSelected = invoiceData.invoices.every(
      (invoice) => selectedRows[invoice.number],
    );
    const newSelectedRows: Record<number, boolean> = {};

    if (allSelected) {
      // Deselect all
    } else {
      // Select all
      invoiceData.invoices.forEach((invoice) => {
        newSelectedRows[invoice.number] = true;
      });
    }
    setSelectedRows(newSelectedRows);
  };

  const toggleSelectRow = (id: number) => {
    setSelectedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const downloadSelectedInvoices = async () => {
    // Create link with selected numbers
    const invoices: number[] = Object.entries(selectedRows)
      .filter(([_, value]) => value === true)
      .map(([key]) => parseInt(key));

    // Call download linkA

    try {
      setLoading(true);
      await handleInvoicesDownload(auth.user?.access_token, invoices);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableContainer sx={{ display: "flex", alignItems: "center" }}>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell>
                <Checkbox onChange={toggleSelectAll} />
                <Tooltip title="Download selected invoices ZIP">
                  <IconButton
                    onClick={async () => await downloadSelectedInvoices()}
                  >
                    <DownloadInvoicesIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell colSpan={11}></TableCell>
              <TableCell style={{ padding: "4px" }} colSpan={2}>
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Grid item> </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h4" component="h4" align="right">
                      {currentPage}/{totalPages}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <PaginationButtons
                      onPrev={handlePrevPage}
                      onNext={handleNextPage}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        component={Paper}
        sx={{ display: "flex", alignItems: "center", mt: 2 }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Date
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Number 
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Company
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Locator
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Subtotal
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Hotel Fees
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                GST
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                PST
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                HST
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                QST
              </TableCell>
              <TableCell
                sx={{ color: theme.palette.primary.main }}
                align="center"
              >
                Total
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.invoices.map((invoice, _) => (
              <React.Fragment key={invoice.number}>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={!!selectedRows[invoice.number]}
                      onChange={() => toggleSelectRow(invoice.number)}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {invoice.createdAt
                      ? format(new Date(invoice.createdAt), "MM/dd/yyyy")
                      : ""}
                  </TableCell>
                  <TableCell align="center">{invoice.number} </TableCell>
                  <TableCell align="center">{invoice.dkNumber}</TableCell>
                  <TableCell align="center">
                    {invoice.recordLocator} ({invoice._count?.lines})
                  </TableCell>
                  <TableCell align="right">
                    ${" "}
                    {invoice.subtotal
                      ? Number(invoice.subtotal).toFixed(2)
                      : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    ${" "}
                    {invoice.hotelFees
                      ? Number(invoice.hotelFees).toFixed(2)
                      : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    $ {invoice.gst ? Number(invoice.gst).toFixed(2) : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    $ {invoice.pst ? Number(invoice.pst).toFixed(2) : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    $ {invoice.hst ? Number(invoice.hst).toFixed(2) : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    $ {invoice.qst ? Number(invoice.qst).toFixed(2) : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    ${" "}
                    {invoice.total ? Number(invoice.total).toFixed(2) : "0.00"}
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Invoice PDF">
                      <IconButton
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await handleInvoiceDownload(
                              auth.user?.access_token,
                              invoice.number,
                            );
                            handleClose(invoice.number);
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <DownloadInvoiceIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Report PDF">
                      <IconButton
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await handleReportDownload(
                              auth.user?.access_token,
                              "Report",
                              invoice.dkNumber,
                              invoice.etlJobId,
                            );
                            handleClose(invoice.number);
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        <DownloadReportIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      onClick={() => {
                        if (openInvoiceId === invoice.number) {
                          setOpenInvoiceId(null); // If the invoice is currently open, close it
                        } else {
                          setOpenInvoiceId(invoice.number); // Otherwise, open this invoice
                        }
                      }}
                    >
                      {openInvoiceId === invoice.number ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    display:
                      openInvoiceId === invoice.number ? "table-row" : "none",
                  }}
                >
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={13}
                  >
                    {openInvoiceId === invoice.number && (
                      <InvoiceItemsTableWrapper
                        invoiceNumber={invoice.number}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Invoices;
