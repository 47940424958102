// theme.ts
import { createTheme, Theme } from "@mui/material/styles";

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#A86B2A", // Change this to your desired primary color
    },
    secondary: {
      main: "#C8602A", // Change this to your desired secondary color
    },
  },
  typography: {
    h5: {
      fontFamily: "Arial",
      fontWeight: 400, // Medium weight
      fontSize: "16px",
      color: "#4F4F4F", // Your desired color
    },
    h4: {
      fontFamily: "Arial",
      fontWeight: 500, // Medium weight
      fontSize: "20px",
      color: "#4F4F4F", // Your desired color
    },
    h3: {
      fontFamily: "Arial",
      fontWeight: 600, // Medium weight
      fontSize: "24px",
      color: "#4F4F4F", // Your desired color
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          // styles applied to the Button component
          backgroundColor: "#fff",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        select: {
          // styles applied to the Button component
          backgroundColor: "#fff",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: "#4F4F4F",
        },
      },
    },
    MuiDialogContentText: {
      defaultProps: {
        color: "#4F4F4F",
        marginTop: 3,
        marginBottom: 3,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // styles applied to the Button component
          backgroundColor: "theme.palette.secondary.main",
          "&:hover": {
            backgroundColor: "theme.palette.secondary.dark",
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          // styles applied to all TableCell components
          fontFamily: "Arial",
          fontSize: "16px",
        },
        head: {
          // styles applied only to header cells
          fontFamily: "Arial",
          fontSize: "18px",
          fontWeight: "bold",
          color: "theme.palette.primary.main",
          backgroundColor: "#C0B7A4",
        },
        body: {
          // styles applied only to non-header cells
          fontFamily: "Arial",
          fontSize: "16px",
          color: "theme.palette.secondary.main",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          // h6: used for form headers
          props: { variant: "h6" },
          style: {
            fontFamily: "Arial",
            fontSize: "20px",
            fontWeight: "bold",
            backgroundColor: "#C0B7A4",
            padding: 1,
          },
        },
      ],
    },
  },
});

export default theme;
