// useAuthLink.js
import { setContext } from '@apollo/client/link/context';
import { useAuth } from "react-oidc-context";

export const useAuthLink = () => {
  const auth = useAuth();
  console.log(`Using access token: ${auth.user?.access_token}`);
  return setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: auth.user ? `Bearer ${auth.user.access_token}` : "",
      }
    }
  });
};
